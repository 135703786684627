/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
   
    
}

.accordion__item + .accordion__item {
    
}

.accordion__button {
   
    
    color: #fff;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    font-weight: bold;
    text-align: left;
    transition: .3s ease-in-out;
    border: none;
    border-radius: .25rem;
}

.accordion__button:hover {
    transition: .3 ease-in-out;
    color: #c7d3e2;
    
   
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    margin-bottom: .5rem;
    background-color: #374151;
    padding: 20px;
    color: #fff;
    border-radius: .25rem;
    /* animation: fadein 0.35s ease-in; */
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
